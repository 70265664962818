<template>
<div>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-7">
                        <div class="font-weight-bold body-1">
                            Detalles del evento
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="7" >

                        <details-item :detailsItems="items"></details-item>

                        <!-- list asist action item -->
                        <v-row v-if="hasAttends" justify="center" align="center" no-gutters class="mt-2 mb-1">
                            <v-col align-self="start" ><span class="font-weight-bold normalText--text">Lista de asistencia</span></v-col>
                            <v-col class="text-right">
                                <v-btn small depressed color="transparent" class="px-0 no-background-hover"  @click="clicked">
                                    <span class="material-symbols-outlined">
                                        fact_check
                                    </span>
                                  
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row justify="center" align="center" no-gutters class="mt-2 mb-1">
                            <v-col align-self="start" ><span class="font-weight-bold normalText--text">Empresas/empleados notificados</span></v-col>
                            <v-col class="text-right">
                                <v-btn small depressed color="transparent" class="px-0 no-background-hover"  @click="openCompaniesModal">
                                    <span class="material-symbols-outlined">
                                        fact_check
                                    </span>
                                </v-btn>
                            </v-col>
                        </v-row>
                        
                        <v-divider></v-divider>

                        <Separator v-if="images.length > 0" title="Galería fotográfica"></Separator>

                        <ImageSlide
                            v-if="images.length > 0"
                            :images="images"
                           
                        ></ImageSlide>

                    </v-col>
                    
                </v-row>

            </div>

               <v-row justify="center" align="end">
                    <v-col cols="12" md="7" class="d-flex justify-end">
                        
                        <primary-button :props="cancelBtnData"></primary-button>
                    </v-col>
                </v-row>
        </template>
    </contentCard>


        <!-- //* Assistance modal -->
        <modal :props="modalProps" @modalResponse="method_handler">
            <!-- Body -->
            <template slot="cardBody" >

                <v-row>
                    <v-col class="mx-0 px-0"> 
                        <datatable :props="tableProps" @method_handler="method_handler">
                            
                        </datatable>
                    </v-col>
                </v-row>
                    
            </template>
            <!-- Buttons -->
            <template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" md="4">
						<secondaryButton
						:props="exportModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" md="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
           
        </modal>


         <!-- //* notifications modal -->
        <modal :props="modalCompaniesProps" @modalResponse="method_handler">
            <!-- Body -->
            <template slot="cardBody" >

                <v-row>
                    <v-col class="mx-0 px-0"> 
                        <datatable :props="tableCompaniesProps" @method_handler="method_handler">
                            
                        </datatable>
                    </v-col>
                </v-row>
                    
            </template>
            <!-- Buttons -->
            <template slot="cardActions">
                <v-row justify="end">
                    
                    <v-col cols="12" md="4" class="text-end" >
                        
                        <primaryButton
                            :props="acceptCompaniesModal"
                            class="ml-md-2 ml-0"
                        ></primaryButton>
                    </v-col>
                </v-row>
            </template> 
           
        </modal>
  </div>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import ImageSlide from '../../../components/ImageSlide.vue';
import SecondaryButton from '../../../components/SecondaryButton.vue';
import Separator from '../../../components/Separator.vue';
import Utils from '../../../helpers/Utils';
// import Utils from '../../../helpers/Utils';
export default {
    components: {
        DetailsItem,
        SecondaryButton,
        Separator,
        ImageSlide
    },
	data(){
		return {
            id: this.$route.params.id,
            eventName: "",
            loading: false,
            hasAttends: false,
            images:[
            ],
           
            items: [
                
            ],
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Events",	
                block: false,
                click: () => {}
            },

            modalProps: {
				visible: false,
				width: '550',
				title: 'Lista de asistencia',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				
			},
			
			exportModal: {
                text: "Exportar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
                loading: false,
                click: ()=>{this.export()}
            },
			acceptModal: {
                text: "Cerrar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				// textColor:'',
				loading:false,
                click: ()=>{this.closeModal()}
            },

            tableProps: {
				headers: [
					{
						text: 'Nombre',
						align: 'left',
						value: 'name',
						class: 'table-b-border'
					},
                    {
						text: 'Empresa',
						align: 'center',
						value: 'company',
						class: 'table-b-border'
					},
                  
                    
				],
				items: [],
				search: '',
				loading: false,
                elevation: '0',
                hideFooter:true
			},

            acceptCompaniesModal: {
                text: "Cerrar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				// textColor:'',
				loading:false,
                click: ()=>{this.closeCompaniesModal()}
            },

            modalCompaniesProps: {
				visible: false,
				width: '550',
				title: 'Empresas/Empleados notificados',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				
			},

            tableCompaniesProps: {
				headers: [
					{
						text: '',
						align: 'left',
						value: 'name',
						class: 'table-b-border',
                        sortable: false,
					},
                  
                    
				],
				items: [],
				search: '',
				loading: false,
                elevation: '0',
                hideFooter:true
			},
         
          
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.cancelBtnData.block = true;
            }
            else{
                this.cancelBtnData.block = false;
            }
        },
        method_handler(object){
			this[object.method_name](object.parameters)
		},
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.event.get(this.id)
                .then((response) => {
                    // this.items.forEach((y) => {
                    //     y.valor = response.data[y.index]
                    // });
                    this.eventName = response.data.name;
                    this.hasAttends = response.data.has_attendance;
                    this.items= [
                        { concepto: 'Nombre del evento', index: 'name', valor: response.data.name },
                        { concepto: 'Fecha del evento', index: 'name', valor: Utils.globalDateFormat(response.data.start_date) },
                        { concepto: 'Vigencia del evento', index: 'name', valor: Utils.globalDateFormat(response.data.end_date) },
                        { concepto: 'Fecha de notificación', index: 'name', valor: Utils.globalDateFormat(response.data?.notification_date??"") },
                        { concepto: 'Lugar del evento', index: 'name', valor: response.data.place??"" },
                        { concepto: 'Dirige/Imparte', index: 'name', valor: response.data.speaker??"" },
                        { concepto: 'Descripción', index: 'name', valor: response.data?.description??"" },
                        
                    ];

                    //attendances
                   
                    response.data.attendance_list?.forEach(item => {
                        if(item.presence)
                            this.tableProps.items.push({name: item.employee.name, company: item.company?.name, value: item.presence});
                    });
                    
                    //companies
                    response.data.companies_notified?.forEach(item => {
                        
                        this.tableCompaniesProps.items.push({name: item.name, id: item.id});
                    });

                    //employees
                    response.data.employees_notified?.forEach(item => {
                        item?.employees?.forEach(emplo => {
                            this.tableCompaniesProps.items.push({name: emplo.name, id: emplo.id});
                            
                        });
                    })

                    //images
                    if(response.data.poster_url){
                        this.images.push({url: response.data.poster_url});
                    }
                    response.data.photo_album.forEach(item => {

                        this.images.push({url: item.photo_url});
                    });

                    
                    
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                })
            ;
		},

        export()
        {
            this.exportModal.loading = true;
            
            this.$api.event.export(this.id)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `event_attendance_${this.eventName}.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.closeModal();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportModal.loading = false;
                })
            ;
        },
       
        clicked(){
            this.modalProps.visible = true;
        },
        closeModal(){
            this.modalProps.visible = false;
        },
        openCompaniesModal(){
            this.modalCompaniesProps.visible = true;
        },
        closeCompaniesModal(){
            this.modalCompaniesProps.visible = false;
        }
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }

    
</style> 