<template>
    <div>
        <v-card flat color="transparent">
            <v-card-text>
                <!-- <v-row align="center" justify="space-between">
					
					<v-spacer></v-spacer>
                    <v-col cols="12" lg="6" md="6" xl="5">
                        <v-row justify="end">
                            
                            
                           
                        </v-row>
                    </v-col>
                   
				</v-row> -->
                <v-row>
                    <v-col class="mx-0 px-0">
                        <datatable
                            :props="tableProps"
                            @method_handler="method_handler"
                            class="table-employess"
                        >
                        </datatable>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <!-- VACATION REQUEST -->
        <modal :props="modalRequestVacationProps" @modalResponse="method_handler">
            <!-- Body -->

            <template slot="cardBody">
                <v-row class="mt-1">
                    <v-col class="px-0">
                        <p class="pb-0">
                            LA/EL EMPLEADO/A
                            <span class="font-weight-bold">{{ requestName }}</span> HA SOLICITADO UN
                            PERIODO VACACIONAL DE
                            <span class="font-weight-bold"
                                >{{ requestDays }}
                                {{ requestDays == 1 || requestDays == '1' ? 'día' : 'DÍAS' }}</span
                            >
                            EN LAS FECHAS COMPRENDIDAS ENTRE EL
                            <span class="font-weight-bold">{{ requestStart }}</span> Y
                            <span class="font-weight-bold">{{ requestEnd }}</span> SI LA SOLICITUD
                            ES PROCEDENTE MARCA LA CASILLA DE VERIFICACIÓN Y FINALIZA CON EL BOTÓN
                            AUTORIZAR, DE LO CONTRARIO SÓLO MARCA NO AUTORIZAR
                        </p>

                        <div class="">
                            <div v-for="(item, index) in sameVacations" :key="index" class="my-2">
                                <span class="font-weight-bold"> * {{ item.name }} </span>
                                <span>
                                    {{ item.message }}
                                </span>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </template>
            <!-- Buttons -->
            <template slot="cardActions">
                <v-col class="py-0">
                    <v-row class="pt-2">
                        <checkboxForm
                            label="AUTORIZO EL PERIODO VACACIONAL SOLICITADO"
                            color="success"
                            :valueInput="requestChecked"
                            :rule="[]"
                            @valueChange="
                                (v) => {
                                    requestChecked = v;
                                }
                            "
                        ></checkboxForm>
                    </v-row>

                    <v-row justify="end">
                        <v-col cols="12" sm="4" class="">
                            <secondaryButton :props="cancelRequestVacationModal"></secondaryButton>
                        </v-col>

                        <v-col cols="12" sm="4" class="mr-sm-2">
                            <primaryButton :props="acceptRequestVacationModal"></primaryButton>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
        </modal>

        <!-- SELF VACATIONS -->
        <modal :props="modalRequestSelfVacationProps" @modalResponse="method_handler">
            <!-- Body -->

            <template slot="cardBody">
                <v-row class="mt-1">
                    <v-col class="px-0">
                        <span class="employee-request-vacation-message">
                            <span class="font-weight-bold">{{
                                currentVacation?.employee?.name
                            }}</span>
                            CON NÚMERO DE EMPLEADO
                            <span class="font-weight-bold">{{
                                currentVacation?.employee?.employe_number
                            }}</span>
                            ADSCRITO A LA EMPRESA
                            <span class="font-weight-bold">{{
                                currentVacation?.employee?.company_name
                            }}</span>
                            EN EL PUESTO DE
                            <span class="font-weight-bold">{{
                                currentVacation?.employee?.position_name
                            }}</span
                            >, TE INFORMAMOS QUE TU SOLICITUD DE VACACIONES DE
                            <span class="font-weight-bold">{{ toTakeDays }}</span> A DISFRUTAR EN EL
                            PERIODO COMPRENDIDO ENTRE
                            <span class="font-weight-bold">{{
                                ut.globalDateFormat(currentVacation?.start_date)
                            }}</span>
                            Y
                            <span class="font-weight-bold">{{
                                ut.globalDateFormat(currentVacation?.end_date)
                            }}</span>
                            HA SIDO APROBADA.
                            <br />
                            <br />
                            TUS VACACIONES SERÁN DESCONTADAS DE TU
                            <span class="font-weight-bold">{{ yearTaken }} AÑO</span> DE SERVICIO AL
                            QUE CORRESPONDEN
                            <span class="font-weight-bold">{{ totalDays }} DÍAS</span> DE DESCANSO,
                            DE LOS CUÁLES HAS DISFRUTADO
                            <span class="font-weight-bold">{{ takenDays }}</span
                            >, TRAS LOS <span class="font-weight-bold">{{ toTakeDays }}</span> QUE
                            DISFRUTARÁS EN ESTE PERIODO TENDRÁS DISPONIBLES
                            <span class="font-weight-bold"
                                >{{ afterAvailableDays }}
                                {{ afterAvailableDays == 1 ? 'DÍA' : 'DÍAS' }}</span
                            >
                            DEL MISMO.
                            <br />
                            <br />
                            DEBERÁS PRESENTARTE DE REGRESO A TUS LABORES EL DIA
                            <span class="font-weight-bold">{{
                                ut.globalDateFormat(currentVacation?.day_to_return)
                            }}</span>
                            <br />
                            <br />
                            PARA HACER EFECTIVA TU SOLICITUD DEBES MARCAR LA CASILLA CON LA LEYENDA
                            <span class="font-weight-bold"
                                >"ACEPTO LOS TÉRMINOS DE LA SOLICITUD"</span
                            >
                            Y HACER CLIC EN EL BOTÓN CONFIRMAR, AL HACERLO EXPRESAS TU CONFORMIDAD
                            DE SOLICITAR Y GOZAR TUS VACACIONES DE ACUERDO A LO QUE ESTABLECE EL
                            <span class="font-weight-bold font-italic"
                                >ARTICULO 76 DE LA LEY FEDERAL DEL TRABAJO</span
                            >
                            CONSIDERANDO LOS DATOS PREVIAMENTE ESTABLECIDOS.
                        </span>
                    </v-col>
                </v-row>
            </template>
            <!-- Buttons -->
            <template slot="cardActions">
                <v-col class="py-0">
                    <v-row class="pt-2">
                        <checkboxForm
                            label="AUTORIZO EL PERIODO VACACIONAL SOLICITADO"
                            color="success"
                            :valueInput="requestChecked"
                            :rule="[]"
                            @valueChange="
                                (v) => {
                                    requestChecked = v;
                                }
                            "
                        ></checkboxForm>
                    </v-row>

                    <v-row justify="end">
                        <v-col cols="12" sm="4" class="">
                            <secondaryButton
                                :props="cancelRequestSelfVacationModal"
                            ></secondaryButton>
                        </v-col>

                        <v-col cols="12" sm="4" class="mr-sm-2">
                            <primaryButton :props="acceptRequestSelfVacationModal"></primaryButton>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
        </modal>
    </div>
</template>
<script>
import NotificationStorage from '../../../../helpers/NotificationStorage';
// import ChipDataTableItem from "../../../components/ChipDataTableItem.vue";
import Utils from '../../../../helpers/Utils';
export default {
    components: {
        //    'chip-item': ChipDataTableItem
    },
    data() {
        return {
            exportBusinessSelected: 'ALL',
            exportStatusSelected: 'ACTIVO',

            requestName: '',
            requestDays: '',
            requestStart: '',
            requestEnd: '',
            requestBody: {},
            requestChecked: false,
            requestId: '',
            sameVacations: [],
            currentVacation: {},
            ut: Utils,
            afterAvailableDays: '',
            toTakeDays: 0,
            takenDays: '',
            totalDays: '',
            yearTaken: '',

            actions: [
                {
                    name: 'Vacaciones',
                    icon: {
                        color: 'secondary',
                        icon: 'mdi-umbrella-beach-outline',
                    },
                    disabled: false,
                    type: 'redirect', //  method, external, redirect
                    action: '/empleados/1/estadisticas',
                    permissions: 'vacations.create',
                    activeModal: false,
                },
                {
                    name: 'Estadísticas',
                    icon: {
                        color: 'secondary',
                        icon: 'mdi-chart-box-outline',
                    },
                    disabled: false,
                    type: 'redirect', //  method, external, redirect
                    action: '/empleados/1/estadisticas',
                    permissions: 'employees.show',
                },

                // {
                // 	name: 'Nueva Eventualidad',
                // 	icon: {
                // 		color: 'secondary',
                // 		icon: 'mdi-text-box-plus-outline'
                // 	},
                // 	disabled: false,
                // 	type: 'redirect',   //  method, external, redirect
                // 	action: '/empleados/1/estadisticas',
                //     permissions: 'employees.create'
                // },
            ],
            tableProps: {
                headers: [
                    {
                        text: 'No.',
                        align: 'left small-th-employees',
                        value: 'employe_number',
                        class: 'table-b-border white-space-normal small-th-employees',
                    },
                    {
                        text: 'Nombre',
                        align: 'left small-th-employees',
                        value: 'name',
                        class: 'table-b-border white-space-normal small-th-employees',
                    },
                    {
                        text: 'Puesto',
                        align: 'center small-th-employees',
                        value: 'position',
                        class: 'table-b-border white-space-normal',
                    },
                    {
                        text: 'Empresa',
                        align: 'center small-th-employees',
                        value: 'business',
                        class: 'table-b-border white-space-normal small-th-employees',
                    },

                    {
                        text: 'Ingreso',
                        align: 'center small-th-employees',
                        value: 'date_i',
                        class: 'table-b-border white-space-normal',
                    },

                    {
                        text: 'Cumpleaños',
                        align: 'center small-th-employees',
                        value: 'birthdate',
                        class: 'table-b-border white-space-normal',
                    },
                    {
                        text: 'Vacaciones Pend',
                        align: 'center small-th-employees',
                        value: 'vacation_days_pending',
                        class: 'table-b-border white-space-normal',
                    },

                    //{
                    //  text: 'Estatus',
                    // align: 'left small-text-8',
                    // value: 'status',
                    //class: 'table-b-border'
                    //},
                    {
                        text: 'Acciones',
                        align: 'right',
                        sortable: false,
                        value: 'actions',
                        class: 'table-b-border',
                    },
                ],
                items: [],
                search: '',
                loading: false,
            },

            snackbar: {
                visible: false,
                color: '',
                text: '',
                timeout: '-1',
            },

            modalRequestVacationProps: {
                visible: false,
                width: '600',
                title: 'Solicitud de vacaciones',
                text: '',
                loading: false,
                bottomLineColor: 'success',
            },

            cancelRequestVacationModal: {
                text: 'No Autorizar',
                classes: '',
                icon: '',
                to: '',
                block: true,
                color: '',
                loading: false,
                disabled: false,
                click: () => {
                    this.rejectVacations();
                },
            },

            acceptRequestVacationModal: {
                text: 'Autorizar',
                icon: '',
                to: '',
                block: true,
                color: 'success',
                textColor: 'white',
                textColorLevel: '0',
                loading: false,
                disabled: true,
                click: () => {
                    this.acceptVacations();
                },
            },

            modalRequestSelfVacationProps: {
                visible: false,
                width: '600',
                title: 'Confirmación de vacaciones',
                text: '',
                loading: false,
                bottomLineColor: 'success',
            },

            cancelRequestSelfVacationModal: {
                text: 'Rechazar',
                classes: '',
                icon: '',
                to: '',
                block: true,
                color: '',
                loading: false,
                disabled: false,
                click: () => {
                    this.rejectVacations();
                },
            },

            acceptRequestSelfVacationModal: {
                text: 'Confirmar',
                icon: '',
                to: '',
                block: true,
                color: 'success',
                textColor: 'white',
                textColorLevel: '0',
                loading: false,
                disabled: true,
                click: () => {
                    this.acceptSelfVacations();
                },
            },
        };
    },
    watch: {
        /**
         * Reset values on "type" change
         */
        [`requestChecked`]: function () {
            this.acceptRequestVacationModal.disabled = true;
            this.acceptRequestSelfVacationModal.disabled = true;
            if (this.requestChecked) {
                this.acceptRequestVacationModal.disabled = false;
                this.acceptRequestSelfVacationModal.disabled = false;
            }
        },

        [`confirmNameInput`]: function () {
            if (this.confirmNameRef.toUpperCase() == this.confirmNameInput.toUpperCase()) {
                this.acceptDeleteModal.disabled = false;
                // console.log(this.confirmNameRef, this.confirmNameInput, this.acceptDeleteModal.disabled)
                return;
            }
            this.acceptDeleteModal.disabled = true;
            // console.log(this.confirmNameRef, this.confirmNameInput, this.acceptDeleteModal.disabled)
        },
    },
    computed: {},

    mounted() {
        this.setNotificationItem();
        this.index();
        //this.index()
    },

    methods: {
        method_handler(object) {
            this[object.method_name](object.parameters);
        },
        setNotificationItem() {
            this.requestId = NotificationStorage.get()?.idRecord ?? '';
            // console.log(this.requestId, "id id")
            NotificationStorage.remove();
        },
        openByNotification() {
            if (this.requestId != undefined && this.requestId != null && this.requestId != '') {
                const found = this.tableProps.items.find(
                    (item) =>
                        item.current_vacation?.id == this.requestId &&
                        item.current_vacation?.status == 'pendiente',
                );
                // console.log(found, "encontrado");
                if (found != undefined) {
                    this.checkVacationRequest(found.parameters);
                }
            }
        },

        index() {
            this.tableProps.loading = true;
            this.tableProps.items = [];
            //call api
            this.$api.employees
                .indexByDeparment(
                    this.$store.state?.userData?.employee?.current_contract?.department?.id,
                    this.selectedStatus,
                )
                .then((resp) => {
                    // console.log(resp, "employees index");
                    this.tableProps.items = resp.data.map((x, index) => {
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));

                        x.actions[0].action = {
                            name: 'EmployeeVacationsCreate',
                            params: { id: x.id },
                        };
                        //check if employee has pending vacations
                        if (x?.current_vacation != null) {
                            if (x.current_vacation.status == 'pendiente') {
                                x.actions[0].icon.color = 'red';
                                x.actions[0].activeModal = true;
                                x.actions[0].type = 'method';
                                x.actions[0].action = 'checkVacationRequest';
                                // const days = Utils.datediff(Utils.parseDate(x.current_vacation.start_date), Utils.parseDate(x.current_vacation.end_date));
                                x['parameters'] = {
                                    sameVacations: x.current_vacation?.same_vacations ?? [],
                                    idVacation: x.current_vacation.id,
                                    start_date: x.current_vacation.start_date,
                                    end_date: x.current_vacation.end_date,
                                    days:
                                        x.current_vacation.start_year_quantity +
                                        x.current_vacation.end_year_quantity,
                                };
                            }
                            const loggedUserEmail = this.$store.state.userData.email;
                            if (
                                x.current_vacation.status == 'autorizada' &&
                                x.email == loggedUserEmail &&
                                this.$gates.hasPermission('area_rol_user.show')
                            ) {
                                //self user with area roll user has vacations to confirm
                                x.actions[0].icon.color = 'red';
                                x.actions[0].activeModal = true;
                                x.actions[0].loading = false;
                                x.actions[0].type = 'method';
                                x.actions[0].action = 'checkVacationSelfRequest';
                                let current_vacation = x.current_vacation;
                                current_vacation['employee'] = x;
                                current_vacation['employee']['company_name'] =
                                    x.current_contract?.branch_office?.company?.name ?? '';
                                current_vacation['employee']['position_name'] =
                                    x.current_contract?.position?.name ?? '';
                                // const days = Utils.datediff(Utils.parseDate(x.current_vacation.start_date), Utils.parseDate(x.current_vacation.end_date));
                                x['parameters'] = {
                                    index,
                                    current_vacation: x.current_vacation,
                                    sameVacations: x.current_vacation?.same_vacations ?? [],
                                    idVacation: x.current_vacation.id,
                                    start_date: x.current_vacation.start_date,
                                    end_date: x.current_vacation.end_date,
                                    days:
                                        x.current_vacation.start_year_quantity +
                                        x.current_vacation.end_year_quantity,
                                };
                            }
                        }

                        // x.actions[1].action = { name: 'EmployeeEdit', params: { id: x.id } }
                        x.actions[1].action = { name: 'EmployeeStatistic', params: { id: x.id } };
                        // x.actions[4].action = { name: 'EmployeeEventCreate', params: { id: x.id } }

                        x['parameters'] = { ...x['parameters'], id: x.id, name: x.name };
                        x.position = x.current_contract?.position?.name ?? '';
                        x.date_i = Utils.globalDateFormat(x.current_contract?.admission_date);
                        x.office = x.current_contract?.branch_office?.name ?? '';
                        x.birthdate = Utils.globalDateFormat(x.birthdate ?? '');
                        x.vacation_days_pending = `${x?.vacation_days_pending ?? 0} días`;
                        x.business = x.current_contract?.branch_office?.company?.name ?? '';

                        // x.status = this.getStatus(x.current_contract.type, x.current_contract.start_date, x.current_contract.end_date);

                        return x;
                    });
                    this.openByNotification();
                })
                .catch((error) => {
                    console.log(error, 'error employees index');
                })
                .finally(() => {
                    this.tableProps.loading = false;
                });
            //console.log(test);
        },

        checkVacationRequest(params) {
            console.log(params);
            this.requestChecked = false;
            this.requestName = params.name;
            this.requestDays = params.days;
            this.requestId = params.idVacation;
            this.requestStart = Utils.globalDateFormat(params.start_date);
            this.requestEnd = Utils.globalDateFormat(params.end_date);
            this.sameVacations = this.processSameVacations(params.sameVacations, params.idVacation);
            console.log(this.sameVacations);
            this.requestBody = {
                start_date: params.start_date,
                end_date: params.end_date,
                employee_id: params.id,
            };
            this.modalRequestVacationProps.visible = true;
        },

        checkVacationSelfRequest(params) {
            this.requestChecked = false;
            this.currentVacation = params.current_vacation;
            this.requestId = params.current_vacation.id;
            this.requestBody = {
                start_date: params.start_date,
                end_date: params.end_date,
                employee_id: params.id,
            };
            this.getSelfVacationsList(this.$store.state.userData.employee.id, params.index);
            console.log(this.currentVacation, 'current ba');
        },

        doubleYearCheck() {
            //2 years
            if (
                this.currentVacation.start_year_number > 0 &&
                this.currentVacation.end_year_number > 0
            ) {
                this.yearTaken = `${this.currentVacation.start_year_number}º y ${this.currentVacation.end_year_number}º`;
                const fYear = this.currentVacation.index.periods.find(
                    (x) => x.year_number == this.currentVacation.start_year_number,
                );
                const lYear = this.currentVacation.index.periods.find(
                    (x) => x.year_number == this.currentVacation.end_year_number,
                );
                this.totalDays = fYear.vacation_days_available + lYear.vacation_days_available;
                this.takenDays =
                    fYear.vacation_days_available -
                    fYear.vacation_days_pending +
                    (lYear.vacation_days_available - lYear.vacation_days_pending);
                this.afterAvailableDays = fYear.vacation_days_pending + lYear.vacation_days_pending;
                return;
            }
            //1 year
            if (
                this.currentVacation.start_year_number > 0 &&
                !(this.currentVacation.end_year_number > 0)
            ) {
                this.yearTaken = `${this.currentVacation.start_year_number}º`;
                const fYear = this.currentVacation.index.periods.find(
                    (x) => x.year_number == this.currentVacation.start_year_number,
                );
                this.totalDays = fYear.vacation_days_available;
                this.takenDays = fYear.vacation_days_available - fYear.vacation_days_pending;
                this.afterAvailableDays =
                    fYear.vacation_days_pending -
                    (this.currentVacation.start_year_quantity +
                        this.currentVacation.end_year_quantity);
                return;
            }
            //2 year
            if (
                !(this.currentVacation.start_year_number > 0) &&
                this.currentVacation.end_year_number > 0
            ) {
                this.yearTaken = `${this.currentVacation.end_year_number}º`;
                const lYear = this.currentVacation.index.periods.find(
                    (x) => x.year_number == this.currentVacation.end_year_number,
                );
                this.totalDays = lYear.vacation_days_available;
                this.takenDays = lYear.vacation_days_available - lYear.vacation_days_pending;
                this.afterAvailableDays = lYear.vacation_days_pending;
                return;
            }
        },

        getSelfVacationsList(id, tableIndex) {
            this.tableProps.items[tableIndex].actions[0].loading = true;
            this.$api.vacation
                .getVacationsByEmployee(id)
                .then((resp) => {
                    this.currentVacation['index'] = {};
                    this.currentVacation['index']['periods'] = resp.data;
                    this.toTakeDays =
                        this.currentVacation.start_year_quantity +
                        this.currentVacation.end_year_quantity;
                    this.toTakeDays = `${this.toTakeDays} ${this.toTakeDays == 1 ? 'Día' : 'Días'}`;
                    this.doubleYearCheck();
                    this.modalRequestSelfVacationProps.visible = true;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    // this.tableProps.loading = false;
                    this.loading = false;
                    this.tableProps.items[tableIndex].actions[0].loading = false;
                });
        },

        getSameVacationMessage(type) {
            switch (type) {
                case 'pendiente':
                    return 'Ha hecho una nueva solicitud de vacaciones en uno o varios días de esta solicitud';

                case 'autorizada':
                case 'confirmada':
                    return 'Tiene una solicitud de vacaciones pendiente en uno o varios días de esta solicitud';

                default:
                    return 'Tiene una solicitud de vacaciones pendiente en uno o varios días de esta solicitud';
            }
        },

        processSameVacations(list, idOwner) {
            let data = [];
            list.forEach((item) => {
                if (item.id != idOwner) {
                    data.push({
                        name: item.employee.name,
                        status: item.status,
                        message: this.getSameVacationMessage(item.status),
                    });
                }
            });
            return data;
        },

        rejectVacations() {
            this.cancelRequestVacationModal.loading = true;

            this.$api.vacation
                .rejectRequest(this.requestId, this.requestBody)
                // eslint-disable-next-line
                .then((response) => {
                    this.$store.dispatch('snackbarSuccess', `Se ha rechazado la solicitud`);
                    this.index();
                    // eslint-disable-next-line
                })
                .catch(() => {
                    this.$store.dispatch('snackbarError', `Error. Intenta de nuevo`);
                })
                .finally(() => {
                    this.modalRequestVacationProps.visible = false;
                    this.cancelRequestVacationModal.loading = false;
                    this.modalRequestSelfVacationProps.visible = false;
                });
        },

        acceptVacations() {
            this.acceptRequestVacationModal.loading = true;
            this.acceptRequestSelfVacationModal.loading = true;

            this.$api.vacation
                .authorizeRequest(this.requestId, this.requestBody)
                // eslint-disable-next-line
                .then((response) => {
                    this.currentVacation['index'] = response.data;
                    this.$store.dispatch('snackbarSuccess', `Se ha autorizado la solicitud`);
                    this.index();
                    // eslint-disable-next-line
                })
                .catch(() => {
                    this.$store.dispatch('snackbarError', `Error. Intenta de nuevo`);
                })
                .finally(() => {
                    this.modalRequestVacationProps.visible = false;
                    this.acceptRequestVacationModal.loading = false;
                    this.acceptRequestSelfVacationModal.loading = false;
                });
        },

        acceptSelfVacations() {
            this.acceptRequestSelfVacationModal.loading = true;

            this.$api.vacation
                .acceptRequest(this.requestId, this.requestBody)
                // eslint-disable-next-line
                .then((response) => {
                    this.currentVacation['index'] = response.data;
                    this.$store.dispatch('snackbarSuccess', `Se ha autorizado la solicitud`);
                    this.index();
                    // eslint-disable-next-line
                })
                .catch(() => {
                    this.$store.dispatch('snackbarError', `Error. Intenta de nuevo`);
                })
                .finally(() => {
                    this.modalRequestVacationProps.visible = false;
                    this.modalRequestSelfVacationProps.visible = false;
                    this.acceptRequestSelfVacationModal.loading = false;
                });
        },
    },
};
</script>

<style lang="scss">
.container-input-share {
    border: 1px solid gray;
}

.outside {
    margin-top: -7px !important;
}

.justi-center {
    justify-content: center;
}

.width-fit {
    max-width: fit-content;
}

.chip-employees-index {
    min-width: 32px;
    justify-content: center;
}

.indexArea-mx-h-modal {
    max-height: 200px;
}

// @media (max-width: $display-breakpoints-xl)  {
//     .width-fit{
//         max-width: fit-content !important;

//     }
// }

// @media (max-width: $display-breakpoints-sm)  {
//     .width-fit{
//         max-width: unset;

//     }
// }
</style>
