<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-left col-md-6">
                        <div class="font-weight-bold body-1">Editar evento</div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-form ref="form" @submit.prevent="save">
                            <event-form
                                ref="EventForm"
                                :edit="true"
                                :values="form"
                                @save="save"
                                :serverErrors="serverErrors"
                                :fullForm="showFullForm"
                            ></event-form>
                        </v-form>
                    </v-col>
                </v-row>
            </div>

            <v-row justify="center" align="end">
                <v-col cols="12" md="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button
                            :props="cancelBtnData"
                            class="mr-0 mr-sm-2 mb-3 mb-sm-0"
                        ></secondary-button>
                        <primary-button
                            :props="saveBtnData"
                            class=""
                        ></primary-button>
                    </v-row>
                </v-col>
            </v-row>
        </template>
    </contentCard>
</template>

<script>
/* eslint-disable */
import Object2Form from "../../../helpers/Object2Form";
import Utils from "../../../helpers/Utils";
import EventForm from "./Form.vue";
export default {
    components: {
        "event-form": EventForm,
    },
    data() {
        return {
            id: this.$route.params.id,
            loading: false,
            serverErrors: {},
            showFullForm: false,
            form: {
                name: "",
                poster_file: [],
                poster_url: "",
                start_date: "",
                end_date: "",
                hour: "",
                place: "",
                speaker: "",
                description: "",

                has_attendance: false,
                attendance_list: [],

                new_photo_album_files: [],
                delete_photo_album_files: [],

                //dump employee list
                assist: [
                    // {name: "nom1", value: false},
                ],
                //dump imgs list
                imgs: [
                    // {url: "https://cdn.vuetifyjs.com/images/john-smirk.png"},
                    // {url: "https://cdn.vuetifyjs.com/images/john-smirk.png"},
                ],

                companies_notified: [
                    // {id:'innovaciones-tecnologicas-mercado-sa-de-cv'}
                ],
                employees_notified: [],
                //aux
                businessData: [
                    {
                        businessName: "empresa nombre 1",
                        businessId: 1,
                        loading: false,
                        employees: [],
                        employees_notified: [],
                    },
                ],
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Events",
                block: false,
                click: () => {},
            },
            saveBtnData: {
                text: "Guardar cambios",
                icon: "mdi-content-save-outline",
                click: this.save,
                loading: false,
                block: false,
            },
            snackbarProps: {
                visible: false,
                color: "success",
                timeout: 3000,
                text: "",
            },
        };
    },
    mounted() {
        window.addEventListener("resize", this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
    methods: {
        onResize() {
            if (window.innerWidth < 600) {
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            } else {
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        validate() {
            if (!Utils.objectIsEmpty(this.serverErrors)) return false;
            return this.$refs.form.validate();
        },
        index() {
            this.loading = true;
            this.$store.state.loading = true;
            this.$api.event
                .get(this.id)
                .then((response) => {
                    const hour = response.data.hour.split(":");
                    this.form = {
                        ...this.form,
                        ...response.data,
                        poster_file: [],
                        hour: `${hour[0]}:${hour[1]}`,
                        employees_notified: [],
                    };

                    //attendances
                    this.form.assist = response.data.attendance_list.map(
                        (item) => {
                            return JSON.parse(
                                JSON.stringify({
                                    name: item.employee.name,
                                    value: item.presence,
                                    idEmployee: item.employee.id,
                                })
                            );
                        }
                    );

                    //imgs
                    this.form.imgs = response.data.photo_album?.map((item) => {
                        let nameImg = item.photo.split("/");
                        nameImg = nameImg[nameImg.length - 1];
                        return {
                            url: item.photo_url,
                            path: item.photo,
                            name: nameImg.replace(/(.{40})..+/, "$1..."),
                        };
                    });

                    if (response.data.status != "active") {
                        this.showFullForm = true;
                    }

                    for (
                        let j = 0;
                        j < response.data?.employees_notified?.length;
                        j++
                    ) {
                        const business = response.data?.employees_notified[j];
                        console.log(business, "esooo");
                        for (let k = 0; k < business.employees.length; k++) {
                            const employee = business.employees[k];
                            this.form.employees_notified.push({
                                id: employee.id,
                                name: employee.name,
                                email: employee.email,
                                businessId: business.id,
                                businessName: business.name,
                            });
                        }
                    }

                    // this.form.employees_notified = response.data?.employees_notified.map((x) => {
                    //     return {id: parseInt(x.employee.id), name: x.name, email: x.email}
                    // });

                    //business
                    console.log(this.form, "el form ");
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                });
        },
        save() {
            // console.log(this.prepareRequest(this.form));
            // return;
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;
            if (this.validate()) {
                let form = this.prepareRequest(this.form);

                this.$api.event
                    .update(this.id, form)
                    .then(() => {
                        this.$store.dispatch(
                            "snackbarSuccess",
                            `Se ha actualizar correctamente`
                        );
                        this.$router.push({ name: "Events" });
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = [];
                        switch (error.response.status) {
                            case 400:
                                //console.log(99, error.response);
                                if ("message" in error.response.data) {
                                    Object.keys(
                                        error.response.data.message
                                    ).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors =
                                            error.response.data.message;
                                        error.response.data.message[x].forEach(
                                            (y) => {
                                                this.serverErrors[x] =
                                                    Utils.deleteWord(x, y);
                                                errors.push(
                                                    Utils.deleteWord(x, y)
                                                );
                                            }
                                        );
                                    });
                                    this.$store.dispatch(
                                        "snackbarError",
                                        errors.join(".<br>")
                                    );
                                }

                                break;
                        }
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    });
            } else {
                this.saveBtnData.loading = false;
                this.$store.state.loading = false;
                this.$store.dispatch("snackbarError", `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request) {
            let data = JSON.parse(JSON.stringify(request));

            // delete data.assist;
            delete data.imgs;

            if (request.poster_file instanceof File)
                data.poster_file = request.poster_file;
            else {
                delete data.poster_file;
                data.poster = request.poster;
            }

            data.has_attendance = data.has_attendance == true ? 1 : 0;

            //att list
            //data.attendance_list = '';
            data.attendance_list = request.assist.map((item) => {
                return {
                    employee_id: item.idEmployee,
                    presence: item.value == true ? 1 : 0,
                };
            });

            //new imgs
            if (request.new_photo_album_files.length > 0) {
                data.new_photo_album_files = request.new_photo_album_files.map(
                    (item) => {
                        console.log(item);
                        return { photo_file: item };
                    }
                );
            }
            if (data.employees_notified == null) data.employees_notified = [];
            if (data.companies_notified == null) data.companies_notified = [];
            // data.employees_notified = [];
            // data.companies_notified = [];
           
            //employess list
            //process already added employess
            data.employees_notified = []
            data.companies_notified = []
            request.employees_notified.forEach((e) => {
                let found = data.employees_notified.findIndex(
                    (x) => x.id == e.businessId
                );
                if (found == -1) {
                    data.employees_notified.push({
                        id: e.businessId,
                        name: e.businessName,
                        employees: [
                            {
                                id: e.id,
                                email: e.email,
                                name: e.name,
                            },
                        ],
                    });
                } else {
                    data.employees_notified[found].employees.push({
                        id: e.id,
                        email: e.email,
                        name: e.name,
                    });
                }
            });

            
            request.businessData.forEach((b) => {
                console.log(
                    b.employees.length,
                    b.selectedBusiness ||
                        (b.employees.length > 0 &&
                            b.employees.length == b.employees_notified.length),
                    "1"
                );
                if (
                    b.selectedBusiness ||
                    (b.employees.length > 0 &&
                        b.employees.length == b.employees_notified.length)
                ) {
                    //add full business
                    data.companies_notified.push({
                        id: b.businessId,
                        name: b.businessName,
                    });
                    
                } else if (b.employees_notified.length > 0) {
                    //some employees

                    b.employees_notified.forEach((e) => {
                        let found = data.employees_notified.findIndex(
                            (x) => x.id == e.businessId
                        );
                        if (found == -1) {
                            data.employees_notified.push({
                                id: e.businessId,
                                name: e.businessName,
                                employees: [
                                    {
                                        id: e.id,
                                        email: e.email,
                                        name: e.name,
                                    },
                                ],
                            });
                        } else {
                            data.employees_notified[found].employees.push({
                                id: e.id,
                                email: e.email,
                                name: e.name,
                            });
                        }
                    });
                }
            });

            console.log(data.employees_notified.length, "length");

            data.companies_notified = Utils.removeDuplicatesArrayObject(
                data.companies_notified,
                (it) => it.id
            );
            for (let a = 0; a < data.employees_notified.length; a++) {
                data.employees_notified[a].employees = Utils.removeDuplicatesArrayObject(
                    data.employees_notified[a].employees,
                    (it) => it.id
                );
            }
            // data.employees_notified = Utils.removeDuplicatesArrayObject(
            //     data.employees_notified,
            //     (it) => it.id
            // );

            delete data.businessData;

            console.log(data, "final");

            return Object2Form.objectToFormData(data, "", []);
        },
    },
};
</script>

<style>
</style>