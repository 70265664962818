<template>
    <div>
        <v-row>
            <v-col>
                <textFieldForm
                    @valueChange="
                        (v) => {
                            values.name = v;
                            delete serverErrors.name;
                        }
                    "
                    :valueInput="values.name"
                    :rule="rules.required"
                    label="Nombre del evento"
                    :errorMessages="serverErrors.name"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <fileInputForm
                    label="Afiche del evento"
                    :multiple="false"
                    :valueInput="values.poster_file"
                    :url="values.poster_url"
                    :rule="[]"
                    @valueChange="
                        (v) => {
                            values.poster_file = v;
                            delete serverErrors.poster_file;
                        }
                    "
                    :errorMessages="serverErrors.poster_file"
                    v-on:update:url="values.poster_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <datePickerForm
                    label="Fecha del evento"
                    :valueInput="values.start_date"
                    :rule="rules.required"
                    @valueChange="
                        (v) => {
                            values.start_date = v;
                            delete serverErrors.start_date;
                        }
                    "
                    :errorMessages="serverErrors.start_date"
                ></datePickerForm>
            </v-col>
            <v-col>
                <datePickerForm
                    label="Vigencia del evento"
                    :valueInput="values.end_date"
                    :rule="rules.required"
                    @valueChange="
                        (v) => {
                            values.end_date = v;
                            delete serverErrors.end_date;
                        }
                    "
                    :errorMessages="serverErrors.end_date"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <timePickerForm
                    label="Hora del evento"
                    :valueInput="values.hour"
                    :rule="rules.required"
                    @valueChange="
                        (v) => {
                            values.hour = v;
                            delete serverErrors.hour;
                        }
                    "
                    :errorMessages="serverErrors.hour"
                ></timePickerForm>
            </v-col>
            <v-col>
                <textFieldForm
                    @valueChange="
                        (v) => {
                            values.place = v;
                            delete serverErrors.place;
                        }
                    "
                    :valueInput="values.place"
                    :rule="rules.required"
                    label="Lugar en el que será el evento"
                    :errorMessages="serverErrors.place"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <datePickerForm
                    label="Fecha de notificación"
                    :valueInput="values.notification_date"
                    :rule="rules.required"
                    @valueChange="
                        (v) => {
                            values.notification_date = v;
                            delete serverErrors.notification_date;
                        }
                    "
                    :errorMessages="serverErrors.notification_date"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <textFieldForm
                    @valueChange="
                        (v) => {
                            values.speaker = v;
                            delete serverErrors.speaker;
                        }
                    "
                    :valueInput="values.speaker"
                    :rule="rules.required"
                    label="Dirige/imparte"
                    :errorMessages="serverErrors.speaker"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <textAreaForm
                    @valueChange="
                        (v) => {
                            values.description = v;
                            delete serverErrors.description;
                        }
                    "
                    :rows="1"
                    :valueInput="values.description"
                    :rule="[]"
                    label="Descripción"
                    :errorMessages="serverErrors.description"
                ></textAreaForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <switchForm
                    title="Evento requiere lista de asistencia"
                    leftLabel="No"
                    rightLabel="Si"
                    :valueInput="values.has_attendance"
                    :rule="[]"
                    @valueChange="
                        (v) => {
                            values.has_attendance = v;
                        }
                    "
                ></switchForm>
            </v-col>
        </v-row>
        <v-row justify="space-between">
            <v-col align-self="center" class="mb-0 pb-0">
                <p class="mb-0 font-weight-bold body-1">Empresas a notificar</p>
            </v-col>
        </v-row>
        <v-row justify="space-between">
            <v-col align-self="center" class="mb-0 pb-0">
                <p class="mb-0 font-weight-bold body-1">
                    Notificar a Todas las empresas
                </p>
            </v-col>
            <v-col
                class="text-right justify-end pr-9 mb-0 pb-0"
                align-self="center"
            >
                <v-checkbox v-model="allSelected"></v-checkbox>
            </v-col>
        </v-row>

        <v-row v-if="loadingCompanies">
            <v-col>
                <v-progress-linear
                :indeterminate="true"
                :query="true"
                color="primary"
                ></v-progress-linear>
            </v-col>
        </v-row>
        <v-row v-if="!loadingCompanies">
            <v-col>
                <v-card v-for="(item, i) in values.businessData" :key="i" class="events-business-list mb-2">
                    <template>
                        <v-expansion-panels  flat>
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                class="pl-3 pr-3"
                                    @click="loadEmployees(item)"
                                >
                                    {{ item.businessName }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <table-items
                                        :props="companyTable"
                                        @methodHandler="method_handler"
                                        :items="item.employees"
                                        :valueInput="item.employees_notified"
                                        @valueChange="
                                            (v) => {
                                                item.employees_notified = v; inspectCheck(item);
                                            }
                                        "
                                    >
                                    </table-items>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </template>
                    <v-checkbox v-model="item.selectedBusiness" class="px-2" :ripple="false" @click="allEmployeesCheck(item)" ></v-checkbox>
                </v-card>
            </v-col>
        </v-row>


        <v-row v-if="fullForm && values.has_attendance">
            <v-col>
                <actionButtonForm
                    label="Confirmar asistencia"
                    :prependInnerIcon="'fact_check'"
                    @clickAppend="
                        () => {
                            openModal();
                        }
                    "
                ></actionButtonForm>
            </v-col>
        </v-row>

        <div v-if="fullForm">
            <v-row>
                <v-col class="mt-3">
                    <fileInputForm
                        label="Fotografías del evento"
                        :multiple="true"
                        :valueInput="values.new_photo_album_files"
                        :rule="[]"
                        @valueChange="
                            (v) => {
                                values.new_photo_album_files = v;
                                delete serverErrors.new_photo_album_files;
                            }
                        "
                        :errorMessages="serverErrors.new_photo_album_files"
                    ></fileInputForm>
                </v-col>
            </v-row>

            <v-row v-for="(item, index) in values.imgs" :key="index">
                <v-col>
                    <imagePrevForm
                        :nameImg="item.name"
                        :url="item.url"
                        v-on:update:url="
                            item.url = $event;
                            removeImg(index);
                        "
                    ></imagePrevForm>
                </v-col>
            </v-row>
        </div>

        <modal :props="modalProps" @modalResponse="method_handler">
            <!-- Body -->
            <template slot="cardBody">
                <!-- SELECT employee -->
                <!-- <v-row>
                    <v-col class="mt-3">
                       <autocompleteOneForm
                            placeholder="Agregar empleado"
                            :valueInput="employeeSelected"
                            :rule="[]"
                            :items="serverItems.employees"
                            @valueChange="(v) => {employeeSelected = v;}"
                            :loading="loadingEmployee"
                            class="no-label"
                        ></autocompleteOneForm>
                       
                    </v-col>
                   <v-col cols="2">
                      <alternativeButton
                            :class="`btn primary mt-3`"
                            :props="addEmployeeBtn"
                        ></alternativeButton>
                   </v-col>
                </v-row> -->

                <v-row>
                    <v-col class="mx-0 px-0">
                        <datatable
                            :props="tableProps"
                            @method_handler="method_handler"
                        >
                            <template v-slot:[`item.value`]="props">
                                <div class="d-flex justify-center">
                                    <v-checkbox
                                        v-model="props.item.value"
                                    ></v-checkbox>
                                </div>
                            </template>
                        </datatable>
                    </v-col>
                </v-row>
            </template>
            <!-- Buttons -->
            <template slot="cardActions">
                <v-row justify="end">
                    <v-col cols="12" class="text-end">
                        <primaryButton
                            :props="acceptModal"
                            ref="acceptMdl"
                        ></primaryButton>
                    </v-col>
                </v-row>
            </template>
        </modal>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    props: ["values", "serverErrors", "fullForm", "edit"],

    data() {
        return {
            someEmployeeRemoved: 0,
            panels: [0],
            loadingCompanies: true,
            loadingEmployee: false,
            employeeSelected: "",
            allSelected: false,
            addEmployeeBtn: {
                text: "",
                icon: "mdi-plus",
                to: "",
                block: false,
                textClass: "",
                click: () => {
                    this.addEmployee();
                },
            },
            modalProps: {
                visible: false,
                width: "550",
                title: "Lista de asistencia",
                text: "Los siguientes compañeros confirmaron su asistencia al evento, corrobora su presencia si aplica.",
                loading: false,
                bottomLineColor: "primary",
            },
            acceptModal: {
                text: "Guardar",
                icon: "",
                to: "",
                block: false,
                color: "primary",
                // textColor:'',
                loading: false,
                click: () => {
                    this.saveItems();
                },
            },

            tableProps: {
                headers: [
                    {
                        text: "Nombre",
                        align: "left",
                        value: "name",
                        class: "table-b-border",
                    },
                    {
                        text: "Check",
                        align: "center",
                        value: "value",
                        sortable: false,
                        class: "table-b-border text-center",
                    },
                ],
                items: [],
                search: "",
                loading: false,
                elevation: "0",
                hideFooter: true,
            },

            companyTable: {
                headers: [
                    {
                        text: "Empleados a notificar",
                        align: "left",
                        value: "name",
                        class: "table-b-border body-1 font-weight-bold",
                        sortable: false,
                    },

                    {
                        text: "",
                        align: "right",
                        sortable: false,
                        value: "data-table-select",
                        class: "table-b-border",
                    },
                ],
                items: [],
                search: "",
                loading: false,
                showSelect: true,
                key: "id",
                showSelect: true,
            },

            rules: {
                required: [(v) => !!v || "Campo requerido"],
                company_id: [(v) => !!v || "El campo es requerido"],
                fileRequired: [
                    (v) => {
                        if (
                            this.values.poster_file.length == 0 &&
                            this.values.poster_url == ""
                        )
                            return "El campo es requerido";
                        return true;
                    },
                ],
            },
            serverItems: {
                employees: [],
            },
        };
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        },
        allSelected: function (v) {
            console.log(v, this.values.businessData);
            
            this.values.businessData.forEach(b => {
                b.selectedBusiness = v;
                this.allEmployeesCheck(b);
            });
            this.values.employees_notified = [];
        },
        
    },
    async mounted() {
        // await this.getEmployee();
        await this.getCompanies();
    },
    methods: {
        method_handler(object) {
            this[object.method_name](object.parameters);
        },
        inspectCheck(item){
            if(item.employees.length > item.employees_notified.length)
                item.selectedBusiness = false;
            if(item.employees.length == item.employees_notified.length)
                item.selectedBusiness = true;
            this.values.employees_notified = this.values.employees_notified.filter(x => x.businessId != item.businessId)
        },
        openModal() {
            this.employeeSelected = "";
            this.tableProps.items = JSON.parse(
                JSON.stringify(this.values.assist)
            );
            this.modalProps.visible = true;

            //to lost focus on first checkbox
            setTimeout(() => {
                this.$refs.acceptMdl.$el.focus();
            });
        },

        saveItems() {
            this.modalProps.visible = false;
            this.values.assist = JSON.parse(
                JSON.stringify(this.tableProps.items)
            );
            console.log(this.values.assist);
        },

        removeImg(index) {
            let deleted = JSON.parse(JSON.stringify(this.values.imgs[index]));
            this.values.imgs.splice(index, 1);
            this.values.delete_photo_album_files.push({
                photo_path: deleted.path,
            });
        },

        addEmployee() {
            if (
                this.employeeSelected == "" ||
                this.employeeSelected == undefined
            )
                return;
            const tableFound = this.tableProps.items.find(
                (item) => item.idEmployee == this.employeeSelected
            );
            if (tableFound != undefined) return;

            const found = this.serverItems.employees.find(
                (item) => item.value == this.employeeSelected
            );
            this.tableProps.items.push({
                name: found.text,
                value: true,
                idEmployee: found.value,
            });
            this.employeeSelected = "";
        },

        getEmployee() {
            this.loadingEmployee = true;
            this.$api.employees
                .index()
                .then((resp) => {
                    console.log(resp.data);
                    this.serverItems.employees = resp.data.map((item) => {
                        return { text: item.name, value: item.id };
                    });
                })
                .catch((error) => {
                    console.log(error, "assing form");
                })
                .finally(() => {
                    this.loadingEmployee = false;
                });
        },

        /**
         * Load companies
         */
        getCompanies() {
            this.loadingCompanies = true;
            this.$api.user
                .getUserBusinessSelect()
                .then((resp) => {
                    // const data = Object.values(
                    //     resp.data.reduce(
                    //         (acc, cur) => Object.assign(acc, { [cur.id]: cur }),
                    //         {}
                    //     )
                    // );
                    // this.companyTable.items = data;
                    this.values.businessData = resp.data.map((x) => {
                        return {
                            loading: false,
                            businessId: x.id,
                            businessName: x.name,
                            employees: [],
                            employees_notified: [],
                            selectedBusiness: false,
                        };
                    });

                    if(this.edit){
                        this.setBusinessData();
                    }
                })
                .catch((error) => {
                    console.log(error, "error eventForm");
                })
                .finally(() => {
                    this.loadingCompanies = false;
                });
        },
        setBusinessData(){
            console.log("run set business")
            for (let i = 0; i < this.values?.companies_notified?.length; i++) {
                let found = this.values.businessData.find((x) => x.businessId == this.values.companies_notified[i].id)
                if(found != undefined){
                    found.selectedBusiness = true;
                }
            }
        },
        allEmployeesCheck(item){
            console.log(item, "allEmployeesCheck")
            item.employees_notified = [];
            this.values.employees_notified = this.values.employees_notified.filter(x => x.businessId != item.businessId)
            if(item.selectedBusiness){
                item.employees_notified = item.employees.map((x) => {
                    return x;
                });
            }
            console.log( this.values.employees_notified, "allEmployeesCheck final final")
        },
        loadEmployees(item) {
            if (item.employees.length > 0) return;

            this.companyTable.loading = true;
            item.loading = true;
            this.$api.employees
                .emailsByBusiness(item.businessId)
                .then((resp) => {
                    // const data = Object.values(
                    //     resp.data.reduce(
                    //         (acc, cur) => Object.assign(acc, { [cur.id]: cur }),
                    //         {}
                    //     )
                    // );
                    // this.companyTable.items = data;
                    item.employees = resp.data.map((x) => {
                        return {
                            id: x.id,
                            name: x.name,
                            email: x.email,
                            businessId: item.businessId,
                            businessName: item.businessName
                        };
                    });

                    if(item.selectedBusiness){
                        item.employees_notified = resp.data.map((x) => {
                            return {
                                id: x.id,
                                name: x.name,
                                email: x.email,
                                businessId: item.businessId,
                                businessName: item.businessName
                            };
                        });
                    }

                    if(this.edit){
                        for (let i = 0; i < this.values.employees_notified.length; i++) {
                            const found = item.employees_notified.find((x) => x.id == this.values.employees_notified[i].id);
                            const found2 = item.employees.find((x) => x.id == this.values.employees_notified[i].id);
                            if(found == undefined && found2 != undefined){
                                item.employees_notified.push(this.values.employees_notified[i])
                            }
                            
                        }
                    }

                    //autocheck
                    let fullList = true;
                    item.employees.forEach(em => {
                        const found = item.employees_notified.find((x) => x.id == em.id);
                        if(found == undefined)
                            fullList = false;
                    });

                    item.selectedBusiness = fullList;
                   
                    console.log(item);
                })
                .catch((error) => {
                    console.log(error, "error eventForm");
                })
                .finally(() => {
                    item.loading = false;
                    this.companyTable.loading = false;
                });
        },
    },
};
</script>

<style lang="scss">
.no-label .v-text-field .v-input__control .v-input__slot input,
textarea {
    margin-top: 0 !important;
}

.justify-end {
    justify-items: flex-end;
}

.events-business-list {
    display: flex;
    align-items: baseline;
}
</style>