var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('textFieldForm',{attrs:{"valueInput":_vm.values.name,"rule":_vm.rules.required,"label":"Nombre del evento","errorMessages":_vm.serverErrors.name},on:{"valueChange":(v) => {
                        _vm.values.name = v;
                        delete _vm.serverErrors.name;
                    }}})],1)],1),_c('v-row',[_c('v-col',[_c('fileInputForm',{attrs:{"label":"Afiche del evento","multiple":false,"valueInput":_vm.values.poster_file,"url":_vm.values.poster_url,"rule":[],"errorMessages":_vm.serverErrors.poster_file},on:{"valueChange":(v) => {
                        _vm.values.poster_file = v;
                        delete _vm.serverErrors.poster_file;
                    },"update:url":function($event){_vm.values.poster_url = $event}}})],1)],1),_c('v-row',[_c('v-col',[_c('datePickerForm',{attrs:{"label":"Fecha del evento","valueInput":_vm.values.start_date,"rule":_vm.rules.required,"errorMessages":_vm.serverErrors.start_date},on:{"valueChange":(v) => {
                        _vm.values.start_date = v;
                        delete _vm.serverErrors.start_date;
                    }}})],1),_c('v-col',[_c('datePickerForm',{attrs:{"label":"Vigencia del evento","valueInput":_vm.values.end_date,"rule":_vm.rules.required,"errorMessages":_vm.serverErrors.end_date},on:{"valueChange":(v) => {
                        _vm.values.end_date = v;
                        delete _vm.serverErrors.end_date;
                    }}})],1)],1),_c('v-row',[_c('v-col',[_c('timePickerForm',{attrs:{"label":"Hora del evento","valueInput":_vm.values.hour,"rule":_vm.rules.required,"errorMessages":_vm.serverErrors.hour},on:{"valueChange":(v) => {
                        _vm.values.hour = v;
                        delete _vm.serverErrors.hour;
                    }}})],1),_c('v-col',[_c('textFieldForm',{attrs:{"valueInput":_vm.values.place,"rule":_vm.rules.required,"label":"Lugar en el que será el evento","errorMessages":_vm.serverErrors.place},on:{"valueChange":(v) => {
                        _vm.values.place = v;
                        delete _vm.serverErrors.place;
                    }}})],1)],1),_c('v-row',[_c('v-col',[_c('datePickerForm',{attrs:{"label":"Fecha de notificación","valueInput":_vm.values.notification_date,"rule":_vm.rules.required,"errorMessages":_vm.serverErrors.notification_date},on:{"valueChange":(v) => {
                        _vm.values.notification_date = v;
                        delete _vm.serverErrors.notification_date;
                    }}})],1)],1),_c('v-row',[_c('v-col',[_c('textFieldForm',{attrs:{"valueInput":_vm.values.speaker,"rule":_vm.rules.required,"label":"Dirige/imparte","errorMessages":_vm.serverErrors.speaker},on:{"valueChange":(v) => {
                        _vm.values.speaker = v;
                        delete _vm.serverErrors.speaker;
                    }}})],1)],1),_c('v-row',[_c('v-col',[_c('textAreaForm',{attrs:{"rows":1,"valueInput":_vm.values.description,"rule":[],"label":"Descripción","errorMessages":_vm.serverErrors.description},on:{"valueChange":(v) => {
                        _vm.values.description = v;
                        delete _vm.serverErrors.description;
                    }}})],1)],1),_c('v-row',[_c('v-col',[_c('switchForm',{attrs:{"title":"Evento requiere lista de asistencia","leftLabel":"No","rightLabel":"Si","valueInput":_vm.values.has_attendance,"rule":[]},on:{"valueChange":(v) => {
                        _vm.values.has_attendance = v;
                    }}})],1)],1),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"align-self":"center"}},[_c('p',{staticClass:"mb-0 font-weight-bold body-1"},[_vm._v("Empresas a notificar")])])],1),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"align-self":"center"}},[_c('p',{staticClass:"mb-0 font-weight-bold body-1"},[_vm._v(" Notificar a Todas las empresas ")])]),_c('v-col',{staticClass:"text-right justify-end pr-9 mb-0 pb-0",attrs:{"align-self":"center"}},[_c('v-checkbox',{model:{value:(_vm.allSelected),callback:function ($$v) {_vm.allSelected=$$v},expression:"allSelected"}})],1)],1),(_vm.loadingCompanies)?_c('v-row',[_c('v-col',[_c('v-progress-linear',{attrs:{"indeterminate":true,"query":true,"color":"primary"}})],1)],1):_vm._e(),(!_vm.loadingCompanies)?_c('v-row',[_c('v-col',_vm._l((_vm.values.businessData),function(item,i){return _c('v-card',{key:i,staticClass:"events-business-list mb-2"},[[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pl-3 pr-3",on:{"click":function($event){return _vm.loadEmployees(item)}}},[_vm._v(" "+_vm._s(item.businessName)+" ")]),_c('v-expansion-panel-content',[_c('table-items',{attrs:{"props":_vm.companyTable,"items":item.employees,"valueInput":item.employees_notified},on:{"methodHandler":_vm.method_handler,"valueChange":(v) => {
                                            item.employees_notified = v; _vm.inspectCheck(item);
                                        }}})],1)],1)],1)],_c('v-checkbox',{staticClass:"px-2",attrs:{"ripple":false},on:{"click":function($event){return _vm.allEmployeesCheck(item)}},model:{value:(item.selectedBusiness),callback:function ($$v) {_vm.$set(item, "selectedBusiness", $$v)},expression:"item.selectedBusiness"}})],2)}),1)],1):_vm._e(),(_vm.fullForm && _vm.values.has_attendance)?_c('v-row',[_c('v-col',[_c('actionButtonForm',{attrs:{"label":"Confirmar asistencia","prependInnerIcon":'fact_check'},on:{"clickAppend":() => {
                        _vm.openModal();
                    }}})],1)],1):_vm._e(),(_vm.fullForm)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"mt-3"},[_c('fileInputForm',{attrs:{"label":"Fotografías del evento","multiple":true,"valueInput":_vm.values.new_photo_album_files,"rule":[],"errorMessages":_vm.serverErrors.new_photo_album_files},on:{"valueChange":(v) => {
                            _vm.values.new_photo_album_files = v;
                            delete _vm.serverErrors.new_photo_album_files;
                        }}})],1)],1),_vm._l((_vm.values.imgs),function(item,index){return _c('v-row',{key:index},[_c('v-col',[_c('imagePrevForm',{attrs:{"nameImg":item.name,"url":item.url},on:{"update:url":function($event){item.url = $event;
                        _vm.removeImg(index);}}})],1)],1)})],2):_vm._e(),_c('modal',{attrs:{"props":_vm.modalProps},on:{"modalResponse":_vm.method_handler}},[_c('template',{slot:"cardBody"},[_c('v-row',[_c('v-col',{staticClass:"mx-0 px-0"},[_c('datatable',{attrs:{"props":_vm.tableProps},on:{"method_handler":_vm.method_handler},scopedSlots:_vm._u([{key:`item.value`,fn:function(props){return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-checkbox',{model:{value:(props.item.value),callback:function ($$v) {_vm.$set(props.item, "value", $$v)},expression:"props.item.value"}})],1)]}}],null,true)})],1)],1)],1),_c('template',{slot:"cardActions"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"text-end",attrs:{"cols":"12"}},[_c('primaryButton',{ref:"acceptMdl",attrs:{"props":_vm.acceptModal}})],1)],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }